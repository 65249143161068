import React from 'react'
import BillingSubBar from '../components/BillingSubBar';
import UploadInvoicing from '../components/UploadInvoicing';
import { ThemeProvider } from '@emotion/react';
import { useTheme } from '@emotion/react';
import StickyHeadTable from '../components/StickyHeadTable';


const BillingViz = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <BillingSubBar />
      <StickyHeadTable />
    </ThemeProvider>
  )
}

export default BillingViz