import React from 'react'
import BillingSubBar from '../components/BillingSubBar';
import Capture from '../components/Capture';

const BillingCapture = () => {
  return (
    <div>
      <BillingSubBar />
      <Capture />
    </div>
  )
}

export default BillingCapture
