import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import ProtectedRoute from "./ProtectedRoute";
import CreateUser from "../pages/CreateUser";
import Layout from "../components/Layout";
import SignIn from "../pages/SignIn";
import Capture from "../components/Capture";
import BillingCapture from "../pages/BillingCapture";
import SignOut from "../pages/SignOut";
import CreateError from "../pages/CreateError";
import CreateSuccess from "../pages/CreateSuccess";
import BillingUpload from "../pages/BillingUpload";
import StickyHeadTable from "../components/StickyHeadTable";
import BillingViz from "../pages/BillingViz";
import MonthDocs from "../pages/MonthDocs";
import Quotes from "../pages/Quotes";

const Routes = () => {
    const {token} = useAuth();
    const publicRoutes = []
    const authRoutes = [
        {
            path: "/",
            element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
            children: [
              {
                path: "/",
                element: <Layout><div>User Home Page</div></Layout>,
              },
              {
                path: "/createUser",
                element: <Layout><CreateUser /></Layout>,
              },
              {
                path: "/createUserError",
                element: <Layout><CreateError /></Layout>,
              },
              {
                path: "/createUserSuccess",
                element: <Layout><CreateSuccess /></Layout>,
              },
              {
                path: "/billing",
                element: <Layout><BillingCapture /></Layout>,
              },
              {
                path: "/custadmin",
                element: <Layout><BillingViz /></Layout>,
              },
              {
                path: "/invoiceadmin",
                element: <Layout><MonthDocs /></Layout>
              },
              {
                path: "/billingupload",
                element: <Layout><BillingUpload /></Layout>,
              },       
              {
                path: "/quotes",
                element: <Layout><Quotes /></Layout>,
              },   
              {
                path: "/logout",
                element: <SignOut />,
              },
            ],
          },
    ]
    const routesNoAuth = [
        {
            path: "/login",
            element: <SignIn />,
        },
    ]
  // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...publicRoutes,
        ...(!token ? routesNoAuth : []),
        ...authRoutes,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}/>;
};

export default Routes;