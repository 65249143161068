import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';
import { useAuth } from '../provider/authProvider';
import jwt_decode from 'jwt-decode';
import '../index.css';

// user settings
const settings = ['Cerrar Sesión'];

function NavBar() {
  // Since we are NOT using drawers, these set the tooltip positions and
  // Display values

  const pageAccess = {
    "DIRECCION": [
      // ["Proveedores", "/providers"], 
      ["Facturación", "/billing"], 
      ["Cotización", "/quotes"], 
      // ["Inventarios", "/inventory"], 
      // ["Cobranzas", "/collections"], 
      // ["Lista de Precios", "/pricing"], 
      // ["Remisiones", "/storage"], 
      // ["Tableros", "/bi"], 
      ["Crear Usuario", "/createUser"],
    ],
    "VENTAS":[
      ["Cotización", "/quotes"], 
      // ["Lista de Precios", "/pricing"], 
    ],
    "ALMACEN":[
      // ["Proveedores", "/providers"],
      // ["Inventarios", "/inventory"],
      // ["Remisiones", "/storage"], 
    ],
    "COBRANZA": [
      // ["Proveedores", "/providers"], 
      ["Facturación", "/billing"], 
      ["Cotización", "/quotes"], 
      // ["Cobranzas", "/collections"], 
    ],
    "SOPORTE": [
      ["Cotización", "/quotes"], 
      // ["Inventarios", "/inventory"],  
    ],
    "FACTURACION": [
      ["Facturación", "/billing"], 
      ["Cotización", "/quotes"], 
    ]
  }

  const token = useAuth()
  const authdata = jwt_decode(token.token)
  const username = authdata.sub
  const area = authdata.area
  const pages = pageAccess[area] ? pageAccess[area] : []
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  
  // Handler for hamburger bars
  const handleOpenNavMenu = (event) => {
    // Set anchor at current target
    // essentially assigns event.currentTarget to anchorElNav
    setAnchorElNav(event.currentTarget);
  };

  // Handler for user menu
  // Probably will only be used for logged-in users
  // So for early stages it is not entirely needed.
  const handleOpenUserMenu = (event) => {
    // Set anchor at current target
    // essentially assigns event.currentTarget to anchorElMenu
    setAnchorElUser(event.currentTarget);
  };

  // These two set the anchor point to null, essentialy un-displaying
  // the tooltip
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    // Essentially says "navbar always on top"
    <AppBar position="sticky" sx={{backgroundColor: '#4b4c4d'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* 
            This is essentially just the left hand side of everything
            inside the navbar
          */}
          {/* Logo container for medium + size */}
          {/* Link is in charge of routing */}
          <Link href='/' sx={{flexGrow:0,
            display: { md: 'none', lg: 'flex' },
          }}>
            {/* Box is in charge of containing logo image */}
            <Box 
              component="img" 
            //   height is a hard limit for flexgrow
              sx = {{height:30,
                mr: 2,
              }}
              alt = "Logo ÚnicoVinos"
              src = '/logope2.png'
            />
          </Link>
          {/* Hamburger bars for xs */}
          {/* Box is essentially a style container */}
          <Box sx={{ flexGrow: 1, display: {md: 'flex', lg: 'none' } }}>
            {/* Icon button gives button properties to the menu */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // This onclick basically sets state, which means it sets
              // the value for anchorElNav
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              {/* 
                This MenuIcon is permanently displayed in xs mode
                as  no state variables affect the sx prop
              */}
              <MenuIcon />
            </IconButton>
            {/* This is the menu for the hamburger bars */}
            <Menu
              id="menu-appbar"  
              // Popper position   
              anchorEl={anchorElNav}
              // This essentially guarantees the menu is always rendered
              // on the bottom left of the button.
              // As both the menu icon and menu are mounted inside the
              // Box component, they share the same initial space.
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              // seems like this is only for seo and accesibility
              // but it should make the component not unmount until
              // state is changed
              keepMounted
              // Prolly not using this since it is for rotate/scale/etc
              // Sets the origin point for transforms
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              // Open/closed status, when anchorElNav state is null,
              // this boolean conversion parses as False
              open={Boolean(anchorElNav)}
              // When closing the menu, either clicking outside or re-clicking
              // hamburger bars, it sets anchorElNav to null
              onClose={handleCloseNavMenu}
              // Fix display position and guarantee this not working in medium
              // or larger screen sizes.
              sx={{
                display: { md: 'block', lg: 'none' },
              }}
            >
              {/* Render menu items with list map */}
              {pages.map((page) => (
                <MenuItem key={page[0]} onClick={handleCloseNavMenu}>
                  <Typography 
                   // Text always centered in button
                   textAlign="center"
                   // This typography happens to be a link component
                   // from mui
                   component={Link}
                   // Pretty simple jump to page
                   href={`/${page[1].toLowerCase()}`}
                   // Override color changing in hover/visit
                   color="textPrimary"
                   // Remove underline
                   underline='none'
                  >
                    {/* Render page name */}
                    {page[0]}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* 
            Middle part of the navbar, renders logo on xs, or  pages in
            sm+ sized screens.
          */}
          {/* Container for small logo to be centered */}
          {/* 
            This link component essentially is just in charge of
            sending the user to the homepage when clicking.
          */}
          <Link href='/' sx={{flexGrow:1,
            display: { md: 'flex', lg: 'none' },
          }}>
            <Box 
                component="img" 
                sx = {{height:30,
                    mr: 2
                    }}
                alt = "Logo ÚnicoVinos"
                src = '/logos_unico.001.png'/>        
          </Link>
          
          {/* Generates the buttons for all pages */}
          <Box sx={{ flexGrow: 100, display: { md: 'none', lg: 'flex' } }}>
            {pages.map((page) => (
              <Button
                // Send to specific page
                component={Link}
                href={`${page[1].toLowerCase()}`}
                key={page[0]}
                // Always set menu status to closed on click just in fucking
                // case
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page[0]}
              </Button>
            ))}
          </Box>
          {/* 
            Right hand size, this bro literally always exists. This has the 
            User options and stuff
          */}
          <Box sx={{ flexGrow: 0 }}>
            {/* This part handles the user avatar. */}
            {/* Tooltip only for accessibility */}
            {/* <Tooltip title={`Usuario Actual: ${username}`}> */}
              {/* Set state on click */}
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* This needs to be some sort of prop later on */}
                {/* 
                  Avatar component, probably no need of images until way later
                  on if customer requires more customization.
                */}
                <Typography sx ={{color:'#fff'}}>
                  Usuario:
                  <br />
                  <b>{username}</b>
                </Typography>
              </IconButton>
            {/* </Tooltip> */}
            {/* 
              As above, this menu exists in the same place as the avatar
              box, meaning this bro exists only in the same position as the
              avatar image. 
            */}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              // See docs for previous menu
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography 
                    textAlign="center" 
                    component={Link}
                    color="textPrimary"
                    underline='none'
                    href="/logout">
                        {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;