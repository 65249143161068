import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from '@mui/material';
import { useAuth } from '../provider/authProvider';
import jwt_decode from 'jwt-decode';
import '../index.css';

// user settings
const settings = ['Cerrar Sesión'];

function BillingSubBar() {
  // Since we are NOT using drawers, these set the tooltip positions and
  // Display values
  const pages = [
    ["Captura", "/billing"],
    ["Cargar Archivo", "/billingupload"],
    ["Administrar Clientes", "/custadmin"],
    ["Documentos del mes", "/invoiceadmin"]
  ]
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  
  // Handler for hamburger bars
  const handleOpenNavMenu = (event) => {
    // Set anchor at current target
    // essentially assigns event.currentTarget to anchorElNav
    setAnchorElNav(event.currentTarget);
  };

  // Handler for user menu
  // Probably will only be used for logged-in users
  // So for early stages it is not entirely needed.
  const handleOpenUserMenu = (event) => {
    // Set anchor at current target
    // essentially assigns event.currentTarget to anchorElMenu
    setAnchorElUser(event.currentTarget);
  };

  // These two set the anchor point to null, essentialy un-displaying
  // the tooltip
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    // Essentially says "navbar always on top"
    <AppBar position="sticky" sx={{backgroundColor: '#5c5d5e', mt:0}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          {/* Generates the buttons for all pages */}
          <Box sx={{ 
            flexGrow: 100, 
            display: { xs: 'flex', md: 'flex', lg: 'flex' } }
          }>
            {pages.map((page) => (
              <Button
                // Send to specific page
                component={Link}
                href={`${page[1].toLowerCase()}`}
                key={page[0]}
                // Always set menu status to closed on click just in fucking
                // case
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page[0]}
              </Button>
            ))}
          </Box>
          {/* 
            Right hand size, this bro literally always exists. This has the 
            User options and stuff
          */}

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default BillingSubBar;