import React from 'react'
import NavBar from './NavBar'
import { useLocation } from 'react-router-dom';

const Layout = ({children}) => {
  const location = useLocation();
  const showNavBar = location.pathname !== '/login';
  return (
    <div>
      {showNavBar && <NavBar />}
      <div>{children}</div>
    </div>
  )
}

export default Layout
