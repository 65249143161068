import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogTitle } from '@mui/material';
import { Typography, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';

const DelDialog = (props) => {

  const onClose = props.onClose;
  const open = props.open;
  const nomCom = props.nomCom;
  const contpaqId = props.contpaqId;


  const navigate = useNavigate()
  const [content, setContent] = React.useState("");

  const handleChange = (val) => {
    setContent(val)
  }
  const handleClose = () => {
    setContent(""); 
    onClose();
  };

  const handleClick = async (e) => {
    e.preventDefault()
    await axios.post(
      "/inv/delete_client",
      {"contpaq_id": contpaqId},
      {headers: {
        "Content-Type":'application/json'
      }
      }
    ).then((r) => {
      navigate(0)
    }).catch((err) => {
      console.error(err)
    })
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle color={'red'} fontWeight={'bold'} align='center'>
        Borrar cliente
      </DialogTitle>
      <Typography color='red' align='center' sx={{ml:3, mr:3, mb:2}}>
        Esta acción no se puede revertir. <br />
        El cliente será eliminado de Comercial Premium
      </Typography>
      <Typography variant='body2' align='center' sx={{ml:3, mr:3, mb:2}}>
        Para eliminar al cliente, escriba <b>{nomCom}</b> <br />
        En la caja que se muestra a continuación.
      </Typography>
      <TextField 
        required 
        sx={{mb:2, ml:3, mr:3}} 
        label="Confirmar borrado"
        onChange={(e) => handleChange(e.target.value)} 
        />
      <Button 
        variant='contained' 
        color='error' 
        disabled = {(content !== nomCom)}
        onClick={handleClick}
        sx={{}}
      >
        Eliminar Cliente
      </Button>
    </Dialog>
  )
}

DelDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default DelDialog