import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../provider/authProvider';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';

const theme = createTheme();


function SignIn() {
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const login = async (username, password) => {
      try {
        const response = await axios.post(
          '/auth/auth', 
          {
            username: username,
            password: password,
          }, 
          {headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Set the content type
          }},
        );
        
        // Assuming the server responds with a JWT in a 'token' field
        const token = response.data.token;
    
        // You may also want to redirect the user to a dashboard or another page
        // window.location.href = '/dashboard';
        setToken(token)
        navigate("/")
        return token;
      } catch (error) {

        navigate("/login")
        return false;
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const username = event.currentTarget.username.value;
      const password = event.currentTarget.password.value;
      login(username, password);
    };

    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src='/logope.png' alt="Logo" />
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Nombre de Usuario"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Mantener mi sesión activa"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 , backgroundColor:'#00135b'}}
              >
                Entrar
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Olvidé mi contraseña
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }

  export default SignIn;