import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { Typography } from "@mui/material";


const CreateError = () => {

  const navigate = useNavigate();

  setTimeout(() => {
    navigate('/createUser')
  }, 3 * 1000)

  return (
    <Typography variant="h4" sx={{color:'red', alignContent:'center'}}>Error al crear el usuario</Typography>
    );
};

export default CreateError;
