import React from 'react';
import CreateUserForm from '../components/CreateUserForm';

const CreateUser = () => {
  return (
    <div>
      <CreateUserForm></CreateUserForm>
    </div>
  )
}

export default CreateUser
