import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { Typography } from "@mui/material";


const CreateSuccess = () => {

  const navigate = useNavigate();

  setTimeout(() => {
    navigate('/createUser')
  }, 3 * 1000)

  return (
    <Typography variant="h4" sx={{color:'green', alignContent:'center'}}>Usuario creado con éxito</Typography>
    );
};

export default CreateSuccess;
