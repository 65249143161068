import React from 'react'
import BillingSubBar from '../components/BillingSubBar';
import UploadInvoicing from '../components/UploadInvoicing';
import { ThemeProvider } from '@emotion/react';
import { useTheme } from '@emotion/react';


const BillingUpload = () => {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <BillingSubBar />
      <UploadInvoicing />
    </ThemeProvider>
  )
}

export default BillingUpload