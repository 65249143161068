// axios: handle promises
import axios from "axios";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// Create empty authorization context
const AuthContext = createContext();

// Authprovider basically extends the properties of our AuthContext
const AuthProvider = ({ children }) => {
    // State to hold the authentication token
    const [token, setToken_] = useState(localStorage.getItem("token"));
  
    // Function to set the authentication token
    const setToken = (newToken) => {
      setToken_(newToken);
    };

    console.log(token)
    
    // Saves the encrypted token to localstorage in the navigator
    useEffect(() => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        localStorage.setItem('token',token);
      } else {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem('token')
      }
    }, [token]);
  
    // Memoized value of the authentication context
    const contextValue = useMemo(
      () => ({
        token,
        setToken,
      }),
      [token]
    );
  
    // Provide the authentication context to the children components
    return (
      <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
  };

  export const useAuth = () => {
    return useContext(AuthContext);
  };
  
  export default AuthProvider;