import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

const SignOut = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setToken();
    navigate("/", { replace: true });
  };

  handleLogout();

  return <>Cerrando Sesión</>;
};

export default SignOut;
