import * as React from 'react';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { Typography, TextField, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import axios from "axios";
import { useNavigate } from 'react-router-dom'

const STATI = [
    {
      value: 'SUSPENDIDO',
      label: 'Suspendido',
    },
    {
      value: 'CANCELADO',
      label: 'Cancelado',
    },
    {
      value: 'CORTESIA',
      label: 'Cortesía',
    },
    {
      value: 'PENDIENTE',
      label: 'Pendiente',
    },
  ];

  const MOTIVES = [
    {
      value: 'CAMBIO DOMICILIO',
      label: 'Cambio de Domicilio',
    },
    {
      value: 'ADEUDO',
      label: 'Adeudo',
    },
    {
      value: 'FALLO COMUNICACION',
      label: 'Fallo de Comunicación',
    },
    {
      value: 'REMODELACION',
      label: 'Remodelación',
    },
    {
      value: 'A PRUEBA',
      label: 'A Prueba',
    },
  ];

const DeactivateDialog = (props) => {

  const onClose = props.onClose;
  const open = props.open;
  const nomCom = props.nomCom;
  const contpaqId = props.contpaqId;

  const [content, setContent] = React.useState("");
  const [motive, setMotive] = React.useState("");
  const navigate = useNavigate()

  const handleChange = (val) => {
    setContent(val)
  }

  const handleMotiveChange = (val) => {
    setMotive(val)
  }

  const handleClose = () => {
    setContent("");  
    setMotive("");  
    onClose();
  };

  const handleClick = async (e) => {
    e.preventDefault()
    await axios.post(
      "/inv/deactivate_client",
      {
        "contpaq_id": contpaqId,
        "new_status": content,
        "reason": motive
      },
      {headers: {
        "Content-Type":'application/json'
      }
      }
    ).then((r) => {
      navigate(0)
    }).catch((err) => {
      console.error(err)
    })
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle fontWeight={'bold'} align='center'>
        Desactivar cliente
      </DialogTitle>
      <Typography variant='body2' align='center' sx={{ml:3, mr:3, mb:2}}>
        Para desactivar al cliente <b>{nomCom}</b>, elija un status <br />
        y motivo en las cajas que se muestran a continuación.
      </Typography>
      <TextField
        fullWidth
        select
        required
        sx={{mb:2, width:300, alignSelf:'center'}} 
        id="status"
        label="Status"
        value={content}
        onChange={(e) => handleChange(e.target.value)}
        >
        {STATI.map((option) => (
            <MenuItem key={option.value} value={option.value}>
            {option.label}
            </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        select
        required
        sx={{mb:2, width:300, alignSelf:'center'}} 
        id="motive"
        label="Motivo Status"
        value={motive}
        onChange={(e) => handleMotiveChange(e.target.value)}
        >
        {MOTIVES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
            {option.label}
            </MenuItem>
        ))}
      </TextField>
      <Button 
        variant='contained'  
        disabled = {(content === "" | motive === "")}
        sx={{}}
        onClick={handleClick}
      >
        Desactivar
      </Button>
    </Dialog>
  )
}

DeactivateDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default DeactivateDialog