import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogTitle } from '@mui/material';
import { Typography, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import axios from 'axios';

const StampDialog = (props) => {

  const onClose = props.onClose;
  const open = props.open;
  const folio = props.folio;
  const serie = props.serie;
  const concepto = props.concepto;


  const navigate = useNavigate()
  const [password, setPassword] = React.useState("");
  const [additionalFile, setAdditionalFile] = React.useState("");

  const handleChange = (val) => {
    setPassword(val)
  }

  const handleAddfile = (val) => {
    setAdditionalFile(val)
  }

  const handleClose = () => {
    setPassword(""); 
    setAdditionalFile("")
    onClose();
  };

  const handleClick = async (e) => {
    e.preventDefault()
    await axios.post(
      "/inv/stamp_invoice",
      {
        'folio' : props.folio,
        'serie' : props.serie,
        'concepto' : props.concepto,
        'password': password,
        'archivo_adicional': additionalFile
      },
      {headers: {
        "Content-Type":'application/json'
      }
      }
    ).then((r) => {
      navigate(0)
    }).catch((err) => {
      console.error(err)
    })
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle color={'forestgreen'} fontWeight={'bold'} align='center'>
        Timbrar Documento
      </DialogTitle>
      <Typography variant='body2' align='center' sx={{ml:3, mr:3, mb:2}}>
        Para timbrar el documento de monitoreo con serie {serie} y folio {folio}, ingrese su contraseña del certificado digital.
      </Typography>
      <TextField 
        required 
        sx={{mb:2, ml:3, mr:3}} 
        label="Contraseña del certificado"
        onChange={(e) => handleChange(e.target.value)} 
      />
      <TextField  
        sx={{mb:2, ml:3, mr:3}} 
        label="Ruta a archivo adicional (en el servidor)"
        onChange={(e) => handleAddfile(e.target.value)} 
      />
      <Button 
        variant='contained' 
        color='success' 
        disabled = {(password == "")}
        onClick={handleClick}
        sx={{}}
      >
        Timbrar Documento
      </Button>
    </Dialog>
  )
}

StampDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default StampDialog