import React, { useReducer, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Box, CssBaseline, Select, MenuItem, Typography} from '@mui/material';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const initialState = {
  username: '',
  password: '',
  confirmPassword: '',
  email: '',
  area: 'DIRECCION'
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'updateUsername':
      return { ...state, username: action.payload };
    case 'updatePassword':
      return { ...state, password: action.payload };
    case 'updateConfirmPassword':
      return { ...state, confirmPassword: action.payload };
    case 'updateEmail':
      return { ...state, email: action.payload };
    case 'updateArea':
      return { ...state, area: action.payload };
    default:
      return state;
  }
};

function MyForm() {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [confirmationError, setConfirmationError] = useState('');
  const navigate = useNavigate();
  const createUser = async (formdata) => {
    try {
      const response = await axios.post(
        '/auth/client', 
        {
          username: formdata.username,
          password: formdata.password,
          email: formdata.email,
          area: formdata.area
        }, 
        {headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // Set the content type
        }},
      );
      if(response.data.success){
        navigate("/createUserSuccess")
      } else {
        navigate("/createUserError")
      }
    } catch (error) {
      navigate("/createUserError")
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (state.password !== state.confirmPassword){
      setConfirmationError('Las contraseñas deben coincidir');
    } else {
      setConfirmationError('');
    }
    createUser(state)
  };

  return (
    <Box component="form" onSubmit={handleFormSubmit} sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
    <CssBaseline />
      <Container component="main" maxWidth="xs">
        <TextField
            label="Usuario"
            value={state.username}
            required
            fullWidth
            sx={{ mt: 2 }}
            onChange={(e) =>
            dispatch({ type: 'updateUsername', payload: e.target.value })
            }
        />
        <TextField
            label="Correo Electrónico"
            value={state.email}
            required
            fullWidth
            sx={{ mt: 2 }}
            type='email'
            onChange={(e) =>
            dispatch({ type: 'updateEmail', payload: e.target.value })
            }
        />
        <TextField
            label="Contraseña"
            value={state.password}
            required
            fullWidth
            sx={{ mt: 2 }}
            type="password"
            onChange={(e) =>
            dispatch({ type: 'updatePassword', payload: e.target.value })
            }
        />
        <TextField
            label="Confirmar Contraseña"
            value={state.confirmPassword}
            required
            fullWidth
            sx={{ mt: 2 }}
            error={confirmationError !== ''}
            helperText={confirmationError}
            type="password"
            onChange={(e) =>
            dispatch({ type: 'updateConfirmPassword', payload: e.target.value })
            }
        />
        <Typography sx= {{mt: 2, ml:1.6}}>Área</Typography>
        <Select
        label="Área"
        value={state.area}
        fullWidth
        sx = {{}}
        onChange={(e) =>
          dispatch({ type: 'updateArea', payload: e.target.value })
        }
        >
          <MenuItem value="DIRECCION">Dirección</MenuItem>
          <MenuItem value="VENTAS">Ventas</MenuItem>
          <MenuItem value="ALMACEN">Almacén</MenuItem>
          <MenuItem value="COBRANZA">Cobranza y Pagos</MenuItem>
          <MenuItem value="SOPORTE">Atención a Clientes</MenuItem>
          <MenuItem value="FACTURACION">Facturación</MenuItem>
        </Select>
        <Button 
          type="submit" 
          sx={{mt:2}} 
          fullWidth
          variant="contained" 
          color="primary">
            Crear Usuario
        </Button>
      </Container>
    </Box>
  );
}

export default MyForm;
