import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";

import React from 'react'

const ProtectedRoute = () => {
    const { token } = useAuth();

    if (!token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
      }
    return <Outlet />
}

export default ProtectedRoute;
