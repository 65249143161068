import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, ButtonGroup } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ApprovalIcon from '@mui/icons-material/Approval';
import axios from 'axios';
import DelDialog from './DelDialog';
import DeactivateDialog from './DeactivateDialog';
import StampDialog from './StampDialog';
import CancelDialog from './CancelDialog';

const columns = [
  { id: 'nombre_comercial', label: 'Nombre Comercial', minWidth: 170 },
  { id: 'rs_monitoreo', label: 'RS Monitoreo', minWidth: 100 },
  {
    id: 'rfc',
    label: 'RFC',
    minWidth: 170,
    align: 'center'
  },
  {
    id: 'mod_pago',
    label: 'Modalidad Pago',
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'tot_mensual',
    label: 'Subtotal Factura',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'tot_fact',
    label: 'Total Factura',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
  },
];

// function createData(name, code, population, size, actions) {
//   const density = population / size;
//   return { name, code, population, size, density, actions };
// }

// const rows = [
//   createData('India', 'IN', 1324171354, 3287263, "nigger"),
//   createData('China', 'CN', 1403500365, 9596961, "nigger"),
//   createData('Italy', 'IT', 60483973, 301340, "nigger"),
//   createData('United States', 'US', 327167434, 9833520, "nigger"),
//   createData('Canada', 'CA', 37602103, 9984670, "nigger"),
//   createData('Australia', 'AU', 25475400, 7692024, "nigger"),
//   createData('Germany', 'DE', 83019200, 357578, "nigger"),
//   createData('Ireland', 'IE', 4857000, 70273, "nigger"),
//   createData('Mexico', 'MX', 126577691, 1972550, "nigger"),
//   createData('Japan', 'JP', 126317000, 377973, "nigger"),
//   createData('France', 'FR', 67022000, 640679, "nigger"),
//   createData('United Kingdom', 'GB', 67545757, 242495, "nigger"),
//   createData('Russia', 'RU', 146793744, 17098246, "nigger"),
//   createData('Nigeria', 'NG', 200962417, 923768, "nigger"),
//   createData('Brazil', 'BR', 210147125, 8515767, "nigger"),
// ];

export default function MonthInvoiceTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [clientType, setClientType] = React.useState("active");
  const [rows, setRows] = React.useState([]);
  const [delDialogOpen, setDelDialogOpen] = React.useState(false);
  const [deaDialogOpen, setDeaDialogOpen] = React.useState(false);
  const [folio, setFolio] = React.useState("");
  const [serie, setSerie] = React.useState("");
  const [concepto, setConcepto] = React.useState("");
  const [contpaqId, setContpaqId] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Too lazy to rename. Delete es stamp, deactivarte es cancel
  const handleClickDelOpen = (r) => {
    setFolio(r["folio"]);
    setConcepto(r["codigo_concepto"]);
    setSerie(r['serie'])
    setDelDialogOpen(true);
  };

  const handleClickDeaOpen = (r) => {
    setFolio(r["folio"]);
    setConcepto(r["codigo_concepto"]);
    setSerie(r['serie'])
    setDeaDialogOpen(true);
  };

  const handleDelClose = (value) => {
    setDelDialogOpen(false);
  };

  const handleDeaClose = (value) => {
    setDeaDialogOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "/inv/get_month_invoices"
      )
      setRows([...rows, ...response.data])
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [])

  const setTo = (status) => {
    setClientType(status)
  }
  return (
    <div>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.filter((r) => ((clientType=="active")? (r.status=="ACTIVO"): (r.status!="ACTIVO")))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell 
                      key={columns[0].id} 
                      align={columns[0].align} 
                      sx={row['cancelado']? {background:'#c73e34'} : (row['timbrado']? {background:'forestgreen'}: {})}
                    >
                      {row[columns[0].id]}
                    </TableCell>
                    <TableCell 
                      key={columns[1].id} 
                      align={columns[1].align}
                      sx={row['cancelado']? {background:'#c73e34'} : (row['timbrado']? {background:'forestgreen'}: {})}
                    >
                      {row[columns[1].id]}
                    </TableCell>
                    <TableCell 
                      key={columns[2].id} 
                      align={columns[2].align}
                      sx={row['cancelado']? {background:'#c73e34'} : (row['timbrado']? {background:'forestgreen'}: {})}
                    >
                      {row[columns[2].id]}
                    </TableCell>  
                    <TableCell 
                      key={columns[3].id} 
                      align={columns[3].align}
                      sx={row['cancelado']? {background:'#c73e34'} : (row['timbrado']? {background:'forestgreen'}: {})}
                    >
                      {row[columns[3].id]}
                    </TableCell>
                    <TableCell 
                      key={columns[4].id} 
                      align={columns[4].align}
                      sx={row['cancelado']? {background:'#c73e34'} : (row['timbrado']? {background:'forestgreen'}: {})}
                    >
                      {row[columns[4].id]}
                    </TableCell>   
                    <TableCell 
                      key={columns[5].id} 
                      align={columns[5].align}
                      sx={row['cancelado']? {background:'#c73e34'} : (row['timbrado']? {background:'forestgreen'}: {})}
                    >
                      {row[columns[5].id]}
                    </TableCell>   
                    <TableCell 
                      key={columns[6].id} 
                      align={columns[5].align}
                      sx={row['cancelado']? {background:'#c73e34'} : (row['timbrado']? {background:'forestgreen'}: {})}
                    >        
                      <Tooltip title={(clientType=="active")? 'Timbrar documento' : 'timbrar documento'}>
                        <Button 
                          variant='contained' 
                          color={(clientType=="active")? "success": "success"}
                          onClick={() => {
                            if (clientType==="active"){
                              handleClickDelOpen(row)
                            }
                          }}
                          disabled={(row['timbrado']==1)||(row['cancelado']==1)}
                          sx={{mr:1}}>
                            {(clientType=="active") && <ApprovalIcon />}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Cancelar documento">
                        <Button 
                          variant='contained' 
                          color="error"
                          disabled={(row['cancelado']==1)}
                          onClick={() => handleClickDeaOpen(row)}
                        >
                          <CancelIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>                     
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <StampDialog 
      open={delDialogOpen} 
      onClose={handleDelClose}
      folio={folio}
      serie={serie}
      concepto={concepto}
      contpaqId={contpaqId}
    />
    <CancelDialog 
      open={deaDialogOpen}
      onClose={handleDeaClose}
      folio={folio}
      serie={serie}
      concepto={concepto}
      contpaqId={contpaqId}
    />
    </div>
  );
}