import React,  {useState} from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import { Typography } from '@mui/material';
import axios from 'axios';

const UploadInvoicing = () => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState('')
  const [mainText, setMainText] = useState('Carga de archivo de facturas')
  const [mainColor, setMainColor] = useState('#000')
  const handleChange = (newFile) => {
    setFile(newFile)
  }

  const handleSubmit = async (e) =>{
    // Avoid multiple submits
    e.preventDefault();
    if(file) {
      // create empty FormData with just the file
      const formData = new FormData();
      formData.append('file', file);
      try {
        // Wait for response
        const response = await axios.post(
          '/inv/data_factory',
          formData
        );
        setError('');
        console.log(response)
        if (response.status === 207){
          setMainText('El archivo se subió con errores, revisa tus descargas para ver los datos que no pudieron entrar.')
          setMainColor('red')
          const blob = new Blob([response.data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'invalid_data.csv';
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else if (response.status === 200) {
          setMainText('Archivo cargado con éxito')
          setMainColor('green')
        } else {
          setMainText('Error al subir el archivo, valida el formato')
          setMainColor('red')
        }

      } catch {
        setError('Error al cargar el archivo');
      }
    }
  }
  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit} 
        noValidate textAlign='center'
        sx={{ mt: '5vh', mb: '20vh', 
        justifyContent:'center' , alignItems:'center'}}>
          <Typography 
            align='center' 
            variant='h5'
            sx={{mt:10, color: mainColor}}
          >
            {mainText}
          </Typography>
          <MuiFileInput 
          required
          fullWidth
          inputProps={{ accept: '.csv, .xlsx' }}
          value={file} 
          onChange={handleChange}
          error = {error!==''}
          helperText = {error}
          sx = {{mt: 5}}
          label="Cargar .csv o .xlsx"
          />
          <Button 
          align='center'
          variant='contained'
          sx={{mt:3}}
          type="submit" >
            Cargar Archivo
          </Button>

      </Box>
    </Container>
  )
}

export default UploadInvoicing
